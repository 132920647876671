import React from 'react';

export default function NoDataBackground() {
  return (
    <div
      style={{
        height: 400,
        backgroundColor: '#f6f6f6',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        marginTop: '20px',
      }}
    >
      <h1
        style={{
          color: 'gray',
          alignSelf: 'center',
        }}
      >
        No data available
      </h1>
    </div>
  );
}
