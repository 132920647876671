import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import { Logo } from 'assets/logos';
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { useSnackbar } from 'notistack';
const Login = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [usuario, setUsuario] = useState('');
    const [contrasena, setContrasena] = useState('');
    // const [errorUsuario, setErrorUsuario] = useState(false);
    // const [errorContrasena, setErrorContrasena] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [todoBien, setTodoBien] = useState(false);
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = 'FIX';
        if (state.authReducer.hash && state.authReducer.loggedIn) {
            if (!Helper.isEmptyObject(state.authReducer.usuario)) {
                login(state.authReducer.hash);
            }
        } else {
            dispatch(AuthActions.setHash(null));
            dispatch(AuthActions.setUsuario(null));
        }
    }, []);
    useEffect(() => {
        setTodoBien((usuario && contrasena) ? true : false);
        // setErrorUsuario(false);
        // setErrorContrasena(false);
    }, [usuario, contrasena]);
    const abrir = tipoUsuario => {
        let lgNext = '/login'; console.log('tipoUsuario', tipoUsuario);
        switch (tipoUsuario) {
            case "administrador":
                lgNext = '/panel/dashboard-admin';
                break;
            case "ferrex-admin":
                lgNext = '/panel/dashboard-admin';
                break;
            case "ferrex-pedidos":
                lgNext = '/panel/dashboard-pedidos';
                break;
            case "logistica":
                lgNext = '/panel/dashboard-admin';
                break;
            default:
                break;
        }
        dispatch(AppActions.setLastVisited(lgNext));
        const { history } = props;
        setTimeout(() => {
            if (history) history.push(lgNext);
        }, 500);

    }
    const login = (hash) => {
        dispatch(AuthActions.setLoginProvider('ingalca'));
        dispatch(AppActions.setActionsDisabled(true));
        dispatch(AuthActions.setHash(hash));
        dispatch(AppActions.setLoading(true));
        console.log('state en login = ', state);
        var options = {
            url: Api.route('p/login'),
            method: 'POST',
            loginProvider: 'ingalca',
            data: {
                tipoCuentaId: 3
            },
            hash: hash
        };
        Api.requestAxios(options, response => {
            Helper.log('login response', response);
            dispatch(AppActions.setActionsDisabled(false));
            dispatch(AppActions.setLoading(false));
            if (response.data.error) {
                dispatch(AuthActions.setHash(null));
                if (response.data.msg) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                }
            } else {
                dispatch(AuthActions.setHash(hash));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setUsuario(response.data.datos.usuario));
                abrir(response.data.datos.usuario.tipo);
            }
        }, error => {
            dispatch(AuthActions.setHash(null));
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            Helper.log('error Login', error);
        });
    };
    const handleSubmit = event => {
        event.preventDefault();
        let todoBien = true;
        if (usuario) {
            // setErrorUsuario(false);
        } else {
            todoBien = false;
            // setErrorUsuario(true);
        }
        if (contrasena) {
            // setErrorContrasena(false);
        } else {
            todoBien = false;
            // setErrorContrasena(true);
        }
        if (todoBien) {
            var hash = btoa(usuario + ':' + contrasena);
            login(hash);
        }
    }
    return (
        <div>
            <div className="w3-display-container full-size">
                <div className="w3-display-middle login-box">
                    <div className="w3-card">
                        <div className="w3-center w3-margin-bottom"><img src={Logo} alt="logo" /></div>
                        <form onSubmit={handleSubmit}>
                            <TextField required fullWidth margin="normal" variant="outlined"
                                id="input-usuario"
                                label="Usuario"
                                type="text"
                                name="usuario"
                                value={usuario}
                                onChange={e => setUsuario(e.target.value)}
                                disabled={(state.appReducer.actionsDisabled) ? true : false} /><br /><br />
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel htmlFor="input-password">Contraseña*</InputLabel>
                                <OutlinedInput
                                    id="input-password"
                                    type={passwordVisible ? 'text' : 'password'}
                                    value={contrasena}
                                    onChange={e => setContrasena(e.target.value)}
                                    name="contrasena"
                                    disabled={(state.appReducer.actionsDisabled) ? true : false}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPasswordVisible((visible) => !visible)}
                                                onMouseDown={e => e.preventDefault()}
                                                edge="end"
                                            >
                                                {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={85}
                                />
                            </FormControl>
                            <p className="w3-center">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    type="submit"
                                    disabled={(state.appReducer.actionsDisabled || !todoBien) ? true : false}>
                                    ENTRAR
                                </Button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(Login);