import React from 'react';
import './tags.css';
const Tags = (props) => {
	const { tags } = props;
	return (
		<ul className="tags">
			{tags.map((tag, index) => (
				<li key={index}>
					<span
						className="tag"
						style={{ backgroundColor: tag.backgroundColor, color: tag.color }}
					>
						{tag.texto}
					</span>
				</li>
			))}
		</ul>
	);
};
export default Tags;
