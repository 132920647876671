import React from 'react';

export default function DiaCard({ value, title }) {
  return (
    <div className="w3-col l3 m4 s6 w3-padding bs-bbox">
      <div className="w3-card w3-padding w3-white w3-round-large">
        <h3>{title}</h3>
        <h1 className="w3-text-theme">{value}</h1>
      </div>
    </div>
  );
}
