import React from 'react';
import { useBarcode } from 'react-barcodes';

const CodigoBarras = ({codigo, options}) => {
    let opciones = {
        background: '#ffffff',
    };
    if (options) {
        opciones = {
            ...opciones,
            ...options
        }
    }console.log('codigo', codigo);
    const { inputRef } = useBarcode({
        value: codigo,
        options: opciones
    });
    return <img ref={inputRef} />;
};

export default CodigoBarras;