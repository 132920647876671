import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config";
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';

import './pedidos.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';


import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";

import { Colors } from 'styles';

import { useBarcode } from 'react-barcodes';
import { productos } from 'datos-prueba';
import { CodigoBarras } from 'components/custom';


// import { Boton, BotonInfo, BotonSuccess, BotonDefault, IconButtonSmall, IconButtonExtraSmall } from 'components/material-ui';

const RadioTheme = withStyles({
    root: {
        color: Colors.THEME,
        '&$checked': {
            color: Colors.THEME_D1,
        },
    }
})((props) => <Radio color="default" {...props} />);


Geocode.setApiKey("AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s");
Geocode.setLanguage("en");
const VerPedido = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);


    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [entrega, setEntrega] = useState('tienda');
    const [moneda, setMoneda] = useState('₲');
    // const [miUbicacionHabilitada, setMiUbicacionHabilitada] = useState(false);
    const [lugares, setLugares] = useState(null);
    const [mapas, setMapas] = useState(null);
    const [mapa, setMapa] = useState(null);
    const [producto, setProducto] = useState(null);
    const [modalCodigoBarras, setModalCodigoBarras] = useState(false);
	const [mapLink, setMapLink] = useState('');
    // const [datosUbicacion, setDatosUbicacion] = useState(null);
    // const [tabUbicacion, setTabUbicacion] = useState('mapa');

    const [pedido, setPedido] = useState(null);
    useEffect(() => {
        getPedido();
    }, []);
    useEffect(() => {
        console.log('pedido', pedido);
    }, [pedido]);

    useEffect(() => {
        if (pedido) {
            let sTotal = 0;
            for (var i in pedido.items) {
                sTotal = sTotal + (pedido.items[i].monto * pedido.items[i].cantidad);
            }
            let tl = sTotal;
            setSubTotal(sTotal);
            setTotal(tl);

			if (pedido.puntoEntrega) {
				setMapLink(`https://www.google.com/maps/dir/?api=1&destination=${pedido.puntoEntrega.lat},${pedido.puntoEntrega.lon}`)
			}
        }
    }, [pedido, entrega]);

    const inicializarPedido = (datos) => {
        setPedido(datos);
        if (datos.puntoEntrega) {
            if (typeof datos.puntoEntrega.lat !== 'undefined' && datos.puntoEntrega.lat !== null && typeof datos.puntoEntrega.lon !== 'undefined' && datos.puntoEntrega.lon !== null) {
                // setCenter({
                //     lat: response.data.datos.puntoEntrega.lat,
                //     lng: response.data.datos.puntoEntrega.lon
                // });  
                const coords = {
                    lat: parseFloat(datos.puntoEntrega.lat),
                    lng: parseFloat(datos.puntoEntrega.lon)
                }
                if (mapas) {
                    const bounds = new mapas.LatLngBounds();
                    bounds.extend(new mapas.LatLng(coords.lat, coords.lng - 0.005562184202671051,));
                    bounds.extend(new mapas.LatLng(coords.lat, coords.lng + 0.005562184202671051,));
                    bounds.extend(new mapas.LatLng(coords.lat, coords.lng,));
                    mapa.fitBounds(bounds);
                }
            }
        }
    }
    const verCodigoBarras = producto => {
        setProducto(producto);
        setModalCodigoBarras(true);
    }
    const cerrarCodigoBarras = () => {
        setProducto(null);
        setModalCodigoBarras(false);
    }
    const getPedido = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('t/pedidos/' + props.pedido.id),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getPedido response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                if (response.data && response.data.datos) {
                    inicializarPedido(response.data.datos);
                }
            }
        }, error => {
            Helper.log('error getPedido', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer pedido', { variant: 'error' });
        });
    };

	const copyUrlToClipboard = () => {
		navigator.clipboard.writeText(mapLink).then(() => {
			enqueueSnackbar('URL del mapa copiado al portapapeles', { variant: 'success'});
		});
	}

    // const elegirUbicacion = (ubcn) => {
    //     setDatosUbicacion({
    //         id: ubcn.id,
    //         lat: parseFloat(ubcn.lat),
    //         lng: parseFloat(ubcn.lon),
    //         direccion: ubcn.direccion,
    //         barrio: ubcn.barrio,
    //         ciudad: ubcn.ciudad,
    //         observaciones: ubcn.observaciones,
    //     });
    // }

    // const onChangeEntrega = (event) => {
    //     setEntrega(event.target.value);
    //     if (event.target.value === 'delivery') {
    //         navigator.geolocation.getCurrentPosition(successGetUbicacion, errorGetUbicacion, optionsGetUbicacion);
    //         setTabUbicacion('mapa');
    //     }
    // };
    // const handleChangeDireccion = (event) => {
    //     setDatosUbicacion({ ...datosUbicacion, direccion: event.target.value });
    // }
    //mapa
    const Marker = () => <FontAwesomeIcon icon={faMapMarkerAlt} color={Colors.THEME} size="3x" style={{
        position: "absolute",
        transform: "translate(-50%, -100%)"
    }} />;
    const center = {
        lat: -25.276750711185308,
        lng: -57.63522231951356
    };
    const zoom = 15;
    // var optionsGetUbicacion = {
    //     enableHighAccuracy: true,
    //     timeout: 5000,
    //     maximumAge: 0
    // };
    // function successGetUbicacion(pos) {
    //     setMiUbicacionHabilitada(true);
    // };
    // function errorGetUbicacion(err) {
    //     setMiUbicacionHabilitada(false);
    //     console.warn('ERROR(' + err.code + '): ' + err.message);
    // };
    const handleApiLoaded = (map, maps, places) => {
        setMapas(maps);
        setMapa(map);
        setLugares(places);
        if (pedido && pedido.puntoEntrega) {
            if (typeof pedido.puntoEntrega.lat !== 'undefined' && pedido.puntoEntrega.lat !== null && typeof pedido.puntoEntrega.lon !== 'undefined' && pedido.puntoEntrega.lon !== null) {
                const coords = {
                    lat: parseFloat(pedido.puntoEntrega.lat),
                    lng: parseFloat(pedido.puntoEntrega.lon)
                }
                const bounds = new maps.LatLngBounds();
                bounds.extend(new maps.LatLng(coords.lat, coords.lng - 0.005562184202671051,));
                bounds.extend(new maps.LatLng(coords.lat, coords.lng + 0.005562184202671051,));
                bounds.extend(new maps.LatLng(coords.lat, coords.lng,));
                map.fitBounds(bounds);
            }
        }

    }
    return (
        <div id="crear-pedido">

            {pedido ? (<div className="w3-padding bs-bbox w3-white">
                <div className="w3-block w3-padding" style={{ height: '50px' }}>
                    <a className="w3-btn w3-ripple w3-blue w3-text-white w3-right w3-large w3-padding-large w3-round w3-margin-right"
                        href={CONFIG.URL_BASE + 't/pedidos/' + pedido.id + '/pdf'}><FontAwesomeIcon icon={faPrint} /> Imprimir</a>
                </div>
				<div className="w3-block w3-border w3-padding-small bs-bbox">
                    <b>Datos cliente</b>
                    {pedido.usuario ? (
						<div className="w3-row">
							<div className="w3-col l3 w3-padding-small bs-bbox">
								<TextField fullWidth margin="normal" size="small" variant="outlined"
									id="input-usuarioNombre"
									label="Nombre"
									type="text"
									name="nombre"
									value={pedido.usuario.nombre} disabled={true} />
							</div>
							<div className="w3-col l3 w3-padding-small bs-bbox">
								<TextField fullWidth margin="normal" size="small" variant="outlined"
									id="input-usuarioApellido"
									label="Apellido"
									type="text"
									name="apellido"
									value={pedido.usuario.apellido} disabled={true} />
							</div>
							<div className="w3-col l3 w3-padding-small bs-bbox">
								<TextField fullWidth margin="normal" size="small" variant="outlined"
									id="input-usuarioEmail"
									label="Email"
									type="text"
									name="email"
									value={pedido.usuario.email} disabled={true} />
							</div>
							<div className="w3-col l3 w3-padding-small bs-bbox">
								<TextField fullWidth margin="normal" size="small" variant="outlined"
									id="input-usuarioTelefono"
									label="Teléfono"
									type="text"
									name="telefono"
									value={pedido.usuario.telefono} disabled={true} />
							</div>
						</div>
					): null}
                </div>
                <div className="w3-block w3-border w3-padding-small bs-bbox">
                    <b>Datos facturación</b>
                    {pedido.datosFacturacion ? (
						<div className="w3-row">
							<div className="w3-col l6 w3-padding-small bs-bbox">
								<TextField fullWidth margin="normal" size="small" variant="outlined"
									id="input-razonSocial"
									label="Razón social"
									type="text"
									name="razonSocial"
									value={pedido.datosFacturacion.razonSocial} disabled={true} />
							</div>
							<div className="w3-col l6 w3-padding-small bs-bbox">
								<TextField fullWidth margin="normal" size="small" variant="outlined"
									id="input-ruc"
									label="RUC"
									type="text"
									name="ruc"
									value={pedido.datosFacturacion.ruc} disabled={true} />
							</div>
						</div>
					): null}
                </div>
                {(pedido.detallesBancard && pedido.detallesBancard.response_code && pedido.detallesBancard.ticket_number && pedido.detallesBancard.authorization_number) ? <div className="w3-col l12 w3-border w3-padding-small bs-bbox">
                    <b>Datos Bancard</b>
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding-small bs-bbox">
                            <TextField fullWidth margin="normal" size="small" variant="outlined"
                                id="input-response_code"
                                label="Código de respuesta"
                                type="text"
                                name="response_code"
                                value={pedido.detallesBancard.response_code || '-'} disabled={true} />
                        </div>
                        <div className="w3-col l4 w3-padding-small bs-bbox">
                            <TextField fullWidth margin="normal" size="small" variant="outlined"
                                id="input-ticket_number"
                                label="Número de ticket"
                                type="text"
                                name="ticket_number"
                                value={pedido.detallesBancard.ticket_number || '-'} disabled={true} />
                        </div>
                        <div className="w3-col l4 w3-padding-small bs-bbox">
                            <TextField fullWidth margin="normal" size="small" variant="outlined"
                                id="input-authorization_number"
                                label="Autorización"
                                type="text"
                                name="authorization_number"
                                value={pedido.detallesBancard.authorization_number || '-'} disabled={true} />
                        </div>
                    </div>
                </div> : null}
                {pedido.items ? <div className="w3-block w3-border w3-padding-small bs-bbox">
                    <b>Productos</b>
                    <table className="w3-table w3-border w3-bordered mi-tabla">
                        <thead className="w3-border">
                            <tr>
                                <th></th>
								<th>Imagen</th>
                                <th>Producto</th>
                                <th>Código de barra</th>
                                <th>SKU</th>
                                <th>Precio</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody className="w3-border">
                            {pedido.items.map((item, index) => <tr key={item.id}>
								<td>
									<Checkbox />
								</td>
                                <td>
                                    {item.multimedia && item.multimedia[0] ? <img className="thumb-container" src={Api.route(item.multimedia[0].url)} /> : null}
                                </td>
                                <td>{item.nombre}</td>
                                <td>
                                    {item.codigo ? <button className="w3-btn w3-ripple w3-light-gray w3-large w3-round" style={{ padding: '0px' }}
                                        type="button"
                                        onClick={() => verCodigoBarras(item)}><CodigoBarras codigo={item.codigo} options={{ width: 2, height: 30, displayValue: false }} /></button> : '--'}
                                </td>
                                <td>{item.codigo ? item.codigo : '--'}</td>
                                <td>{'₲ ' + Helper.numberUi(item.monto)}</td>
                                <td> {item.cantidad}</td>
                                <td>{'₲ ' + Helper.numberUi(item.cantidad * item.monto)}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div> : null}
                {pedido.items && pedido.items.length > 0 ? <table className="w3-table w3-border w3-bordered">
                    <tbody className="w3-border">
                        <tr>
                            <td className="w3-padding">
                                <h3>Subtotal</h3>
                            </td>
                            <td className="w3-padding">
                                <h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(subTotal)}</h3>
                            </td>
                        </tr>
                        {/* {pedido.puntoEntrega ? <tr>
                            <td className="w3-padding">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="gender" name="entrega" value={'delivery'}>
                                        <FormControlLabel value="delivery" control={<RadioTheme />} label="Delivery" />
                                    </RadioGroup>
                                </FormControl>
                            </td>
                            <td className="w3-padding">
                                <h4 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(precioDelivery)} </h4>
                            </td>
                        </tr> : null} */}
                        {pedido.puntoEntrega ? (
							<>
							<tr>
								<td colSpan="2" className="w3-padding">
									<div className="w3-row">
										<div className="w3-col l6" style={{ height: '260px' }}>
											<GoogleMapReact
												bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
												defaultCenter={center}
												defaultZoom={zoom}
												yesIWantToUseGoogleMapApiInternals
												onGoogleApiLoaded={({ map, maps, places }) => handleApiLoaded(map, maps, places)}
											>
												{(pedido.puntoEntrega) ? <Marker lat={pedido.puntoEntrega.lat} lng={pedido.puntoEntrega.lon} /> : null}
											</GoogleMapReact>
										</div>
										<div className="w3-col l6 w3-padding-small bs-bbox">
											{pedido.puntoEntrega.direccion ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
												name="direccion"
												type="text"
												label="Dirección"
												value={pedido.puntoEntrega.direccion} /> : null}<br />
											{(pedido.puntoEntrega.barrio && pedido.puntoEntrega.barrio.nombre) ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
												name="barrio"
												type="text"
												label="Barrio"
												value={pedido.puntoEntrega.barrio.nombre} /> : null}<br />
											{(pedido.puntoEntrega.ciudad && pedido.puntoEntrega.ciudad.nombre) ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
												name="ciudad"
												type="text"
												label="Ciudad"
												value={pedido.puntoEntrega.ciudad.nombre} /> : null}<br />
											{pedido.puntoEntrega.observaciones ? <TextField required fullWidth size="small" margin="normal" variant="outlined"
												name="observaciones"
												type="text"
												label="Observaciones"
												value={pedido.puntoEntrega.observaciones} /> : null}<br />
											<div className="w3-right-align">
												<span className="link" onClick={copyUrlToClipboard}><FontAwesomeIcon icon={faClipboard} className="w3-margin-right"/></span>
												<a className="link" href={mapLink} target="_blank" rel="noopener noreferrer">Abrir en Google Maps</a>
											</div>
										</div>
									</div>
								</td>
                        	</tr>
							<tr>
								<td className="w3-padding">
									<h3>Costo envío</h3>
								</td>
								<td className="w3-padding">
									<h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(pedido.costoEnvio)}</h3>
								</td>
							</tr>
							</>
						) : null}
                        <tr>
                            <td className="w3-padding">
                                <h3>Total</h3>
                            </td>
                            <td className="w3-padding">
                                <h2 style={{ textAlign: 'right', color: Colors.THEME }}>{moneda} {Helper.numberUi(pedido.montoTotal)}</h2>
                            </td>
                        </tr>
                    </tbody>
                </table> : null}
                <Divider />
                <div className="w3-row w3-white">
                    <div className="w3-col l6" style={{ padding: '30px 16px' }}>
                        <button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
                            type="button"
                            onClick={() => props.cancelar()}>Cerrar</button>
                    </div>
                </div>
            </div> 
			) : null}
            {(modalCodigoBarras) ? <div className="w3-modal w3-show">
                <div className="w3-modal-content w3-animate-zoom w3-round-large">
                    <div className="w3-row">
                        <div className="w3-col w3-right" style={{ width: '50px' }}>
                            <IconButton aria-label="delete" onClick={() => cerrarCodigoBarras()}>
                                &nbsp;<FontAwesomeIcon icon={faTimes} />&nbsp;
                            </IconButton>
                        </div>
                        <div className="w3-rest w3-padding w3-center w3-text-gray">
                            <h2 style={{ margin: 0 }}>{producto.nombre}</h2>
                        </div>
                    </div>
                    <div className="w3-container w3-padding">
                        <div className="w3-row w3-white">
                            <div className="w3-col l12 w3-center bs-bbox" style={{ padding: '30px 16px' }}>
                                <CodigoBarras codigo={producto.codigo} options={{ width: 3, height: 100, background: Colors.GRAY_LIGHT, }} />
                            </div>
                        </div>
                        <div className="w3-row w3-white">
                            <div className="w3-col l12 w3-center bs-bbox" style={{ padding: '30px 16px' }}>
                                <button className="w3-btn w3-ripple w3-light-gray w3-large w3-padding-large w3-round"
                                    type="button"
                                    onClick={() => cerrarCodigoBarras()}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    );
};
export default withRouter(VerPedido);