import React from 'react';
import { useState } from 'react';
import Chart from 'react-google-charts';

import NoDataBackground from './NoDataBackground';

export default function MasVendidosChart({ products }) {
  const [hover, setHover] = useState(false);
  const options = {
    chartArea: { width: '50%' },
    colors: ['#4285f4'],
    hAxis: {
      title: 'Cantidad',
      minValue: 0,
    },
    vAxis: {
      title: 'Producto',
    },
    tooltip: { isHtml: true, trigger: 'visible' },
  };

  const nameViewer = (product) => {
    if (!hover) {
      if (product.split('').length > 30) {
        return product.split('').slice(0, 30).join('');
      } else {
        return product;
      }
    } else {
      return product;
    }
  };

  const hoverToggle = (state) => {
    setHover(state);
  };

  const dataReceived = products.map((product) => {
    const nombre = `<b>Producto: </b><p>${product.nombre}</p>\n`;
    const cantidad = `<b>Cantidad: </b><p>${product.cantidad}</p>`;
    const paddingStyle = {
      padding: 5,
    };
    const tooltipText = `<div style={${paddingStyle}}>${
      nombre + cantidad
    }</div>`;
    return [product.codigo, product.cantidad, tooltipText];
  });

  const data = [
    [
      'nombre',
      'cantidad',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...dataReceived,
  ];

  return products && products.length > 0 ? (
    <div
      onMouseEnter={() => hoverToggle(true)}
      onMouseLeave={() => hoverToggle(false)}
    >
      <Chart
        chartType="BarChart"
        width={'100%'}
        height={'400px'}
        data={data}
        options={options}
      />
    </div>
  ) : (
    <NoDataBackground />
  );
}
