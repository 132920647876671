import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './sidenav.css';
import { StateContext } from 'context';
const Navbutton = (props) => {
    const { label, icon } = props;
    const state = useContext(StateContext);
    return (
        <button onClick={props.click} className={"w3-bar-item w3-button " + state.appReducer.sideMenuClass.hover} style={{ boxSizing: 'border-box' }}>
            <div className="w3-row">
                <div className="w3-col" style={{ width: '30px' }}>
                    <FontAwesomeIcon icon={icon} className="w3-margin-right  w3-color-theme-l3" />
                </div>
                <div className="w3-rest">{label}</div>
            </div>
        </button>
    );
};
export default Navbutton;