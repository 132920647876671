import React, { useState, useEffect } from 'react';
import './alert.css';
const Alert = (props) => {
    const containerClass = {
        success: 'w3-pale-green',
        info: 'w3-pale-blue',
        warning: 'w3-pale-yellow',
        danger: 'w3-pale-red'
    };
    const contentClass = {
        success: 'w3-text-teal',
        info: 'w3-text-blue-grey',
        warning: 'w3-text-brown',
        danger: 'w3-text-dark-gray'
    };
    const closeClass = {
        success: 'w3-text-teal',
        info: 'w3-text-blue-grey',
        warning: 'w3-text-brown',
        danger: 'w3-text-dark-gray'
    };
    const clickable = (typeof props.clickable === 'undefined') ? true : props.clickable;
    const [show, setShow] = useState(true);
    useEffect(() => {
        function inicializar() {
            if (props.dismissOnTimeout) {
                setTimeout(() => {
                    setShow(false);
                }, parseInt(props.dismissOnTimeout));
            }
        }
        inicializar();
    }, [props.dismissOnTimeout]);
    return (show ? <div>
        <div style={{ marginTop: '5px', boxSizing: 'border-box' }} className={"w3-panel w3-card w3-padding w3-block " + containerClass[props.type]}>
            {clickable && <span onClick={props.close} className={"w3-button w3-tiny w3-display-topright " + closeClass[props.type]}><b>x</b></span>}
            <div className={contentClass[props.type]}>{props.content}</div>
        </div>
    </div> : '');
};
export default Alert;