import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { DispatchContext } from 'context';
import { Api } from 'services';
import { AppActions } from 'store';
import MasVendidosChart from './MasVendidosChart';

export default function MasVendidosMes() {
	const [meses, setMeses] = useState([]);
	const [mesData, setMesData] = useState([]);
	const [mesSelected, setMesSelected] = useState();
	const dispatch = useContext(DispatchContext);

	useEffect(() => {
		dispatch(AppActions.setLoading(true));
		const options = {
			url: Api.route('t/reportes/meses-posibles'),
			method: 'GET',
		};

		Api.requestAxios(options, (response) => {
			console.log('API request options: ', response);
			if (response.error) {
				console.error('There was an error');
				dispatch(AppActions.setLoading(false));
			} else {
				setMeses(response.data.datos);
				dispatch(AppActions.setLoading(false));
			}
		});
		setMesSelected(dateFormat());
	}, []);

	useEffect(() => {
		dispatch(AppActions.setLoading(true));
		const options = {
			url: Api.route(
				`t/reportes/productos-mas-vendidos/${mesSelected || dateFormat()}`
			),
			method: 'GET',
		};
		Api.requestAxios(options, (response) => {
			console.log(response);
			if (response.error) {
				console.error('There was an error');
				dispatch(AppActions.setLoading(false));
			} else {
				setMesData(response.data.datos);
				dispatch(AppActions.setLoading(false));
			}
		});
	}, [mesSelected]);

	const handleChange = (e) => {
		setMesSelected(e.target.value);
	};

	const dateFormat = () => {
		let month = new Date().getMonth() + 1;
		const year = new Date().getFullYear();

		if (month.toString().length < 2) {
			month = '0' + month;
		}

		return year + '-' + month.toString();
	};

	return (
		<>
			<h1>Productos más vendidos por mes</h1>
			<div className="w3-row" style={{ maxWidth: '20%' }}>
				<div className="w3-col w3-padding-small bs-bbox"></div>
				<FormControl size="small" variant="filled" fullWidth>
					<InputLabel>Seleccione un mes </InputLabel>
					<Select
						value={mesSelected ? mesSelected : dateFormat()}
						onChange={handleChange}
						defaultValue={dateFormat()}
					>
						{meses &&
							meses.map((mes) => {
								return (
									<MenuItem value={mes.key} key={mes.key}>
										{mes.label}
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>
			</div>
			{mesData && <MasVendidosChart products={mesData} />}
		</>
	);
}
