import React from 'react';
import Chart from 'react-google-charts';

export default function DataChart({ data, title }) {
  return (
    data && (
      <div className="w3-row">
        <div className="w3-col l12 w3-padding bs-bbox">
          <div className="w3-card w3-padding w3-white w3-round-large">
            <h3>Histórico {title}</h3>
            <Chart chartType="Bar" width="100%" height="400px" data={data} />
          </div>
        </div>
      </div>
    )
  );
}
