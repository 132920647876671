//css
import './app.css';
//js
import React, { useContext } from 'react';
import {
	Router,
	Route,
	withRouter,
	Switch,
	Redirect,
} from 'react-router-dom';
import { StateContext, DispatchContext } from 'context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/es';
import { AppStorage } from 'utils';

/* componentes */
import { Loading } from 'components/w3';
import { Header } from 'components/header';
import { MenuDrawer } from 'components/menu-drawer';
/* páginas */
import { Sidemenu } from 'app/sidemenu';
import { Sidecontent } from 'components/sidenav';
import { Login } from 'app/autenticacion';
import { DashboardAdmin } from 'app/dashboard-admin';
import { DashboardPedidos } from 'app/dashboard-pedidos';
import { Administradores } from 'app/administradores';
import { Listas } from 'app/listas';
import { Pedidos } from 'app/pedidos';
import { PedidosHistorial } from 'app/pedidos-historial';
import { AppActions } from 'store';
import { MasVendidosMes } from './reportes/mas-vendidos';
import { MasVendidosRango } from './reportes/mas-vendidos';
import { VentasRango } from './reportes/ventas-con-productos';

const App = (props) => {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const notistackRef = React.createRef();
	const { history, location } = props;
	const { pathname } = location;
	const labels = {
		'/panel/dashboard-admin': 'Dashboard admin',
		'/panel/dashboard-pedidos': 'Dashboard pedidos',
		'/panel/usuarios': 'Usuarios',
		'/panel/listas': 'Listas',
		'/panel/pedidos': 'Pedidos',
		'/panel/pedidos-historial': 'Pedidos historial',
	};
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};
	const logout = () => {
		dispatch(AppActions.setLoginHash(null));
		AppStorage.clearAll();

		if (history) {
			history.push('/login');
		}
	};
	return (
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			ref={notistackRef}
			action={(key) => (
				<IconButton aria-label="cerrar" onClick={onClickDismiss(key)}>
					<FontAwesomeIcon icon={faTimes} color={'#ffffff'} size="xs" />
				</IconButton>
			)}
		>
			<MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
				<>
					<MenuDrawer />
					{/* {<Toasts messages={toasts} />} */}
					{state.appReducer.loading ? <Loading fondo="rgba(0,0,0,0.2)" /> : ''}
					{/* <Switch>
                        <Route exact path="/" render={(props) => <Inicio {...props} />} />
                        <Route exact path="/acerca-de-nyp" render={(props) => <AcercaDeNyp {...props} />} />
                        <Route exact path="/productos" render={(props) => <Productos {...props} />} />
                        <Route exact path="/producto/:productoId" render={(props) => <Producto {...props} />} />
                        <Route exact path="/carrito" render={(props) => <Carrito {...props} />} />
                        <Route exact path="/contacto" render={(props) => <Contacto {...props} />} />
                        <Route exact path="/finalizar-compra" render={(props) => <FinalizarCompra {...props} />} />
                        <Route exact path="/olvide-mi-contrasena" render={(props) => <OlvideMiContrasena {...props} />} />
                        <Route exact path="/cambiar-contrasena-olvidada" render={(props) => <CambiarContrasenaOlvidada {...props} />} />
                        <Route exact path="/registro" render={(props) => <Registro {...props} />} />
                        <Route path="/mi-perfil" render={(props) => <MiPerfil {...props} />} />
                    </Switch> */}
					<Switch>
						<Route
							exact
							path="/login"
							render={(props) => <Login {...props} />}
						/>
						{state.authReducer.loggedIn && state.authReducer.hash ? (
							<Route path="/panel">
								<Router history={history}>
									<Sidemenu pathname={pathname} />
									<Sidecontent labels={labels}>
										<Switch>
											{/* admin */}
											<Route
												exact
												path="/panel/dashboard-admin"
												render={(props) => <DashboardAdmin {...props} />}
											/>
											<Route
												path="/panel/administradores"
												render={(props) => (
													<Administradores {...props} logout={logout} />
												)}
											/>
											<Route
												path="/panel/listas"
												render={(props) => (
													<Listas {...props} logout={logout} />
												)}
											/>
											<Route
												path="/panel/pedidos"
												render={(props) => (
													<Pedidos {...props} logout={logout} />
												)}
											/>
											<Route
												path="/panel/pedidos-historial"
												render={(props) => (
													<PedidosHistorial {...props} logout={logout} />
												)}
											/>
											<Route
												path="/panel/reportes/mas-vendidos-mes"
												render={() => <MasVendidosMes />}
											/>
											<Route
												path="/panel/reportes/ventas-rango"
												render={() => <VentasRango />}
											/>
											<Route
												path="/panel/reportes/mas-vendidos-rango"
												render={() => <MasVendidosRango />}
											/>
											{/* pedidos */}
											<Route
												path="/panel/dashboard-pedidos"
												render={(props) => <DashboardPedidos {...props} />}
											/>
										</Switch>
									</Sidecontent>
								</Router>
							</Route>
						) : (
							<Redirect to="/login" />
						)}
						<Route exact path="/">
							{state.authReducer.loggedIn &&
								state.authReducer.hash &&
								state.authReducer.usuario &&
								state.authReducer.usuario.tipo === 'ferrex-admin' ? (
								<Redirect to="/panel/dashboard-admin" />
							) : (
								<Redirect to="/login" />
							)}
							{state.authReducer.loggedIn &&
								state.authReducer.hash &&
								state.authReducer.usuario &&
								state.authReducer.usuario.tipo === 'ferrex-pedidos' ? (
								<Redirect to="/panel/dashboard-pedidos" />
							) : (
								<Redirect to="/login" />
							)}
						</Route>
					</Switch>
				</>
			</MuiPickersUtilsProvider>
		</SnackbarProvider>
	);
};
export default withRouter(App);
