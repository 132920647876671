import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  faHome,
  faPowerOff,
  faCaretRight,
  faPeopleCarry,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';

import { AppStorage, Helper } from 'utils';
import { Navlink, Navtoggle, Navbutton } from 'components/sidenav';
import { LogoBlanco } from 'assets/logos';
import { AppActions, AuthActions } from 'store';
import { DispatchContext, StateContext } from 'context';
import './sidemenu.css';

const Sidemenu = (props) => {
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);
  const [toggleMenu, setToggleMenu] = useState({
    usuarios: AppStorage.getData('toggle-usuarios'),
    transportistas: AppStorage.getData('toggle-transportistas'),
    pedidos: AppStorage.getData('pedidos'),
  });
  const [isNavTabOpen, setIsNavTabOpen] = useState(false);

  useEffect(() => {
    dispatch(AppActions.setLoading(false));
    dispatch(AppActions.setActionsDisabled(false));
    document.title = 'FIX';
    if (state.authReducer.hash && state.authReducer.loggedIn) {
      if (!Helper.isEmptyObject(state.authReducer.usuario)) {
        // const { history } = props;
        // if (history) { history.push('/login'); };
      }
    } else {
      // const { history } = props;
      // if (history) { history.push('/login'); };
    }
  }, []);
  const logout = () => {
    dispatch(AuthActions.setHash(null));
    dispatch(AuthActions.setLoggedIn(null));
    AppStorage.clearAll();
    const { history } = props;
    if (history) {
      history.push('/login');
    }
  };
  const toggleNav = (target) => {
    let toggle = { ...toggleMenu };
    toggle[target] = !toggle[target];
    setToggleMenu(toggle);
  };
  return (
    <nav
      id="nav-side"
      className={
        'w3-sidebar w3-bar-block w3-collapse w3-animate-left w3-card-2 w3-border-right ' +
        state.appReducer.sideMenuClass.background
      }
    >
      <div className="w3-center w3-border-bottom" style={{ padding: '20px' }}>
        <img src={LogoBlanco} style={{ width: '150px' }} alt="logo" />
      </div>
      {toggleMenu &&
      state.authReducer &&
      state.authReducer.usuario &&
      state.authReducer.usuario.tipo === 'ferrex-admin' ? (
        <>
          <Navlink
            label="Dashboard"
            to="/panel/dashboard-admin"
            icon={faHome}
          />
          <Navtoggle
            label="Pedidos"
            toggle={toggleMenu.pedidos}
            click={() => toggleNav('pedidos')}
            icon={faPeopleCarry}
          />
          {/* <Navlink label="Administradores" to="/panel/administradores" icon={faUsers} /> */}
          {toggleMenu.pedidos ? (
            <div style={{ paddingLeft: '20px' }}>
              <Navlink
                label="Pendientes"
                to="/panel/pedidos"
                icon={faCaretRight}
              />
              <Navlink
                label="Historial"
                to="/panel/pedidos-historial"
                icon={faCaretRight}
              />
            </div>
          ) : null}

          <Navtoggle
            label="Reportes"
            icon={faChartBar}
            toggle={isNavTabOpen}
            click={() => setIsNavTabOpen((prev) => !prev)}
          />
          {isNavTabOpen ? (
            <div style={{ paddingLeft: '20px' }}>
              <Navlink
                label="Productos más vendidos por mes"
                to="/panel/reportes/mas-vendidos-mes"
                icon={faCaretRight}
              />
			   <Navlink
                label="Descargar ventas por rango"
                to="/panel/reportes/ventas-rango"
                icon={faCaretRight}
              />
			  <Navlink
                label="Productos más vendidos por rango"
                to="/panel/reportes/mas-vendidos-rango"
                icon={faCaretRight}
              />
            </div>
          ) : null}
        </>
      ) : null}
      {toggleMenu &&
      state.authReducer &&
      state.authReducer.usuario &&
      state.authReducer.usuario.tipo === 'ferrex-pedidos' ? (
        <>
          <Navlink
            label="Dashboard"
            to="/panel/dashboard-pedidos"
            icon={faHome}
          />
          <Navtoggle
            label="Pedidos"
            toggle={toggleMenu.pedidos}
            click={() => toggleNav('pedidos')}
            icon={faPeopleCarry}
          />
          {toggleMenu.pedidos ? (
            <div style={{ paddingLeft: '20px' }}>
              <Navlink
                label="Pendientes"
                to="/panel/pedidos"
                icon={faCaretRight}
              />
              <Navlink
                label="Historial"
                to="/panel/pedidos-historial"
                icon={faCaretRight}
              />
            </div>
          ) : null}
        </>
      ) : null}
      <Navbutton label="Cerrar" click={logout} icon={faPowerOff} />
    </nav>
  );
};

export default withRouter(Sidemenu);
