import React, { useState, useContext, useEffect } from 'react';
import CONFIG from 'config';
import { Helper } from 'utils';
import { Api } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faChevronRight,
	faTimes,
	faExclamationTriangle,
	faCircle,
} from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import VerPedido from './ver-pedido';
import CrearPedido from './crear-pedido';
import EditarPedido from './editar-pedido';
import { PanelContainer } from 'components/sidenav';
import { Tags } from 'components/w3';
import { Switch } from 'components/switch';
import { withRouter } from 'react-router-dom';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './pedidos.css';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
import { InputLabel } from '@material-ui/core';

const BootstrapInput = withStyles((theme) => ({
	input: {
		fontSize: 16,
		padding: '10px 26px 10px 12px',
	},
}))(InputBase);

const BotonAccionInfo = withStyles({
	root: {
		background: `${Colors.INFO}`,
		margin: '4px',
		color: 'white',
		'&:hover': {
			background: `${Colors.INFO}`,
		},
	},
	label: {
		textTransform: 'uppercase',
	},
})(Button);
const RadioTheme = withStyles({
	root: {
		color: Colors.THEME,
		'&checked': {
			color: Colors.THEME_D1,
		},
	},
})((props) => <Radio color="default" {...props} />);
const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));

const useSelectStyles = makeStyles((theme) => ({
	formControl: {
		margin: '0px 2px',
		minWidth: 200,
	},
	selectEmpty: {
		marginTop: '0px',
	},
}));

const PedidosHistorial = (props) => {
	const classes = useStyles();
	const selectClass = useSelectStyles();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	const [pedidos, setPedidos] = useState([]);
	const [pedido, setPedido] = useState(null);
	const [tipoSelected, setTipoSelected] = useState('todos');
	const [panelCrear, setPanelCrear] = useState(false);
	const [panelVer, setPanelVer] = useState(false);
	const [panelEditar, setPanelEditar] = useState(false);
	const [modalEliminar, setModalEliminar] = useState(false);
	const [modalCancelarPedido, setModalCancelarPedido] = useState(false);
	const [motivoCancelacion, setMotivoCancelacion] = useState('');
	const [otroMotivoCancelacion, setOtroMotivoCancelacion] = useState('');
	const [requestEnProceso, setRequestEnProceso] = useState(false);
	const [paginador, setPaginador] = useState({
		cuantos: 10,
		selectCuantos: [
			{ label: '10', value: 10 },
			{ label: '20', value: 20 },
			{ label: '30', value: 30 },
			{ label: '50', value: 50 },
			{ label: '100', value: 100 },
		],
		numResultados: 0,
		paginaActual: 0,
		cantidadPaginas: 1,
		maxSize: 10,
	});
	const [filtros, setFiltros] = useState({
		cuantos: paginador.cuantos,
		desde: 0,
		filtros: {
			finalizado: false,
		},
	});
	const tipoOptions = [
		{ id: 'todos', label: 'Todos los pedidos' },
		{ id: 'online-envio', label: 'Pago online con envío' },
		{ id: 'online-retiro', label: 'Pago online con retiro de sucursal' },
		{ id: 'envio', label: 'Envío con pago contra entrega' },
		{ id: 'envio-interior', label: 'Envío al interior' },
	];
	const [configPedidos, setConfigPedidos] = useState(null);
	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Productos';
		if (state.authReducer.usuario && state.authReducer.usuario.id) {
			buscarResultados(filtros);
		} else {
			const { history } = props;
			if (history) history.push('/login');
		}
	}, []);

	const cambioCuantos = (event) => {
		const cts = event.target.value;
		const fts = { ...filtros, desde: 0, cuantos: cts };
		setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
		buscarResultados(fts);
	};

	const changeSelectTipo = (tipo) => {
		console.log('tipo', tipo);
		setTipoSelected(tipo);
		let fts = { ...filtros };
		if (tipo === 'todos') {
			if (fts.filtros && fts.filtros.tipo !== undefined) {
				delete fts.filtros.tipo;
			}
		} else {
			fts.filtros.tipo = tipo;
		}
		setFiltros(fts);
		buscarResultados(fts);
	};

	const buscarResultados = (fts) => {
		setFiltros(fts);
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('t/ver-pedidos'),
			method: 'POST',
			data: fts,
		};
		Api.requestAxios(
			options,
			(response) => {
				Helper.log('searchPedidos response', response);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				if (response.data.error) {
					enqueueSnackbar(response.data.msg, { variant: 'error' });
				} else {
					setPaginador((pgndr) => {
						const cantidadPaginas = Math.ceil(response.data.datos.numResultados / fts.cuantos);
						return {
							...pgndr,
							numResultados: response.data.datos.numResultados,
							cantidadPaginas: cantidadPaginas,
							paginaActual: pgndr.paginaActual > cantidadPaginas ? pgndr.paginaActual - 1 : pgndr.paginaActual
						};
					});
					let peds = [];
					for (var i in response.data.datos.pedidos) {
						let ped = {
							...response.data.datos.pedidos[i],
						};
						ped.estados = {
							procesando: ped.procesando ? true : false,
							cancelado: ped.cancelado ? true : false,
							enCamino: ped.enCamino ? true : false,
							entregado: ped.entregado ? true : false,
							finalizado: ped.pagado == '1' ? true : false,
							listo: ped.listo ? true : false,
							pagado: ped.pagado == '1' ? true : false,
						};
						peds.push(ped);
					}
					setPedidos(peds);
				}
				setRequestEnProceso(false);
			},
			(error) => {
				Helper.log('error searchPedidos', error);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				setRequestEnProceso(false);
			}
		);
	};
	const atras = () => {
		if (filtros.desde > 0) {
			if (!requestEnProceso) {
				const fts = {
					...filtros,
					desde: paginador.cuantos * (paginador.paginaActual - 1),
				};
				setFiltros(fts);
				setPaginador((pgndr) => ({
					...pgndr,
					paginaActual: paginador.paginaActual - 1,
				}));
				buscarResultados(fts);
			}
		}
	};
	const siguiente = () => {
		if (filtros.desde + paginador.cuantos < paginador.numResultados) {
			if (!requestEnProceso) {
				const fts = {
					...filtros,
					desde: paginador.cuantos * (paginador.paginaActual + 1),
				};
				setFiltros(fts);
				setPaginador((pgndr) => ({
					...pgndr,
					paginaActual: paginador.paginaActual + 1,
				}));
				buscarResultados(fts);
			}
		}
	};
	//VER
	const ver = (data) => {
		setPedido({ ...data, abm: 'ver' });
		setPanelVer(true);
	};
	//EDITAR
	const editar = (data) => {
		console.log('pedido', data);
		setPedido({ ...data, abm: 'editar' });
		setPanelEditar(true);
	};
	//ELIMINAR
	const eliminar = (data) => {
		setPedido({ ...data, abm: 'eliminar' });
		setModalEliminar(true);
	};
	const confirmarEliminar = () => {
		var options = {
			url: Api.route('t/pedidos/' + pedido.id),
			method: 'DELETE',
		};
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		Api.requestAxios(
			options,
			(response) => {
				Helper.log('deletePedido response', response);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				if (response.data.error) {
					enqueueSnackbar(response.data.msg, { variant: 'error' });
				} else {
					enqueueSnackbar(response.data.msg, { variant: 'success' });
					buscarResultados(filtros);
					cancelar();
				}
			},
			(error) => {
				Helper.log('error deletePedido', error);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				enqueueSnackbar('Error al eliminar administrador', {
					variant: 'error',
				});
			}
		);
	};
	//CANCELAR PEDIDO
	const cancelarPedido = (data) => {
		setMotivoCancelacion('');
		setPedido({ ...data, abm: 'cancelar' });
		setModalCancelarPedido(true);
	};
	const onChangeMotivo = (event) => {
		console.log('event.target.value', event.target.value);
		setMotivoCancelacion(event.target.value);
		if (event.target.value === 'otro') {
			setOtroMotivoCancelacion('');
		}
	};
	const onChangeOtroMotivo = (event) => {
		console.log('event.target.value', event.target.value);
		setOtroMotivoCancelacion(event.target.value);
	};
	//CANCELAR CANCELAR PEDIDO
	const cancelarCancelarPedido = (data) => {
		setMotivoCancelacion('');
		setPedido(null);
		setModalCancelarPedido(false);
	};
	const confirmarCancelarPedido = () => {
		let motivo = motivoCancelacion;
		let todoBien = true;
		if (motivo === 'otro') {
			if (otroMotivoCancelacion) {
				motivo = otroMotivoCancelacion;
			} else {
				enqueueSnackbar('Debe indicar el motivo de cancelación del pedido', {
					variant: 'error',
				});
				todoBien = false;
			}
		}
		if (!motivo) {
			enqueueSnackbar('Debe indicar el motivo de cancelación del pedido', {
				variant: 'error',
			});
			todoBien = false;
		}
		if (todoBien) {
			var options = {
				url: Api.route('t/pedidos/' + pedido.id + '/cancelar'),
				method: 'POST',
				data: {
					motivo: motivo,
				},
			};
			console.log('options', options);
			dispatch(AppActions.setLoading(true));
			dispatch(AppActions.setActionsDisabled(true));
			Api.requestAxios(
				options,
				(response) => {
					Helper.log('confirmarCancelarPedido response', response);
					dispatch(AppActions.setLoading(false));
					dispatch(AppActions.setActionsDisabled(false));
					if (response.data.error) {
						enqueueSnackbar(response.data.msg, { variant: 'error' });
					} else {
						enqueueSnackbar(response.data.msg, { variant: 'success' });
						buscarResultados(filtros);
						cancelar();
					}
				},
				(error) => {
					Helper.log('error confirmarCancelarPedido', error);
					dispatch(AppActions.setLoading(false));
					dispatch(AppActions.setActionsDisabled(false));
					enqueueSnackbar('Error al cancelar pedido', { variant: 'error' });
				}
			);
		}
	};
	//CANCELAR
	const cancelar = () => {
		setPedido(null);
		setPanelVer(false);
		setPanelCrear(false);
		setPanelEditar(false);
		setModalEliminar(false);
		setModalCancelarPedido(false);
	};
	const onClickOpciones = (event, index) => {
		let peds = [...pedidos];
		peds[index].anchorEl = event.currentTarget;
		setPedidos(peds);
	};

	const getPedido = (accion, id) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('t/pedidos/' + id),
			method: 'GET',
		};
		Api.requestAxios(
			options,
			(response) => {
				Helper.log('getPedido response', response);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				if (response.data.error) {
					enqueueSnackbar(response.data.msg, { variant: 'error' });
				} else {
					if (response.data.datos) {
						switch (accion) {
							case 'ver':
								ver(response.data.datos);
								break;
							case 'editar':
								editar(response.data.datos);
								break;
							default:
								break;
						}
					}
				}
			},
			(error) => {
				Helper.log('error getPedido', error);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
			}
		);
	};
	const getEstado = (ped) => {
		let status = {
			estado: 'Pendiente',
			color: '#2067FA',
		};
		if (ped.recibido) {
			status = {
				estado: 'Pendiente',
				color: '#2067FA',
			};
		}
		if (ped.procesando) {
			status = {
				estado: 'Procesando',
				color: '#666D92',
			};
		}
		if (ped.listo) {
			status = {
				estado: 'Listo',
				color: '#00C58D',
			};
		}
		if (ped.enCamino) {
			status = {
				estado: 'Entregado',
				color: '#008f39',
			};
		}
		if (ped.cancelado) {
			status = {
				estado: 'Cancelado',
				color: '#FC5C63',
			};
		}
		return status;
	};
	const clickOpcion = (opcion, data, index) => {
		let peds = [...pedidos];
		peds[index].anchorEl = null;
		setPedidos(peds);
		// setAnchorEl(null);
		switch (opcion) {
			case 'ver':
				getPedido('ver', data.id);
				break;
			case 'editar':
				getPedido('editar', data.id);
				break;
			case 'eliminar':
				eliminar(data);
				break;
			case 'cancelar':
				cancelarPedido(data);
				break;
			case 'estado-procesando':
				cambiarEstado(data.id, { campo: 'procesando', valor: true });
				break;
			case 'estado-listo':
				cambiarEstado(data.id, { listo: true });
				break;
			case 'estado-enCamino':
				cambiarEstado(data.id, { campo: 'enCamino', valor: true });
				break;
			case 'estado-entregado':
				cambiarEstado(data.id, { campo: 'entregado', valor: true });
				break;
			case 'estado-pagado':
				cambiarEstado(data.id, { campo: 'pagado', valor: true });
				break;
			case 'estado-cancelado':
				cambiarEstado(data.id, { campo: 'cancelado', valor: true });
				break;
			case 'estado-finalizado':
				cambiarEstado(data.id, { campo: 'finalizado', valor: true });
				break;
			default:
				break;
		}
	};
	const onChangeEstado = (e, data, index) => {
		console.log('e', e);
		let peds = [...pedidos];
		peds[index].anchorEl = null;
		setPedidos(peds);
		let opcion = e.target.name.split('-')[0];
		// cambiarEstado(data.id, { campo: opcion, valor: e.target.checked });
		cambiarEstado(data.id, { [opcion]: e.target.checked });
	};
	const marcarListo = (e, data, index) => {
		console.log('e', e);
		let peds = [...pedidos];
		peds[index].anchorEl = null;
		setPedidos(peds);
		let opcion = e.target.name.split('-')[0];
		// cambiarEstado(data.id, { campo: opcion, valor: e.target.checked });
		cambiarEstado(data.id, { listo: true });
	};
	const desmarcarListo = (e, data, index) => {
		console.log('e', e);
		let peds = [...pedidos];
		peds[index].anchorEl = null;
		setPedidos(peds);
		let opcion = e.target.name.split('-')[0];
		// cambiarEstado(data.id, { campo: opcion, valor: e.target.checked });
		cambiarEstado(data.id, { listo: false });
	};
	const cambiarEstado = (id, estado) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('t/pedidos/' + id),
			method: 'PUT',
			data: estado,
		};
		Api.requestAxios(
			options,
			(response) => {
				Helper.log('cambiarEstado response', response);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				if (response.data.error) {
					enqueueSnackbar(response.data.msg, { variant: 'error' });
				} else {
					buscarResultados(filtros);
				}
			},
			(error) => {
				Helper.log('error cambiarEstado', error);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
			}
		);
	};
	const getNombreCompleto = (user) => {
		let nombre = '--';
		if (user) {
			if (user.nombre) {
				nombre = user.nombre;
			}
			if (user.nombre && user.apellido) {
				nombre = user.nombre + ' ' + user.apellido;
			}
		}
		return nombre;
	};
	const getTagProperties = (tipo) => {
		let properties = {
			texto: '...',
			backgroundColor: '#fff',
			color: '#000'
		}

		switch (tipo) {
			case 'online-retiro':
				properties = {
					texto: 'Retiro del local',
					backgroundColor: '#438BCA',
					color: '#fff',
				}
				break;
			case 'envio-interior':
				properties = {
					texto: 'Envío al interior',
					backgroundColor: '#F56D06',
					color: '#fff',
				}
				break;
			case 'online-envio':
				properties = {
					texto: 'Pagado con envío',
					backgroundColor: '#B24598',
					color: '#fff',
				}
				break;
			case 'envio':
				properties = {
					texto: 'Pago contra entrega',
					backgroundColor: '#339933',
					color: '#fff',
				}
				break;
		}

		return properties
	}
	return (
		<div>
			<div className="w3-row">
				<div
					className="w3-col w3-padding-small bs-bbox"
					style={{ width: '220px' }}
				>
					<div className="w3-row">
						<div className="w3-col l6 w3-padding-small bs-bbox">
							{tipoOptions.length > 0 ? (
								<FormControl
									variant="outlined"
									size="small"
									className={selectClass.formControl}
								>
									<InputLabel id="select-tipo-label">Tipo de pedido</InputLabel>
									<Select
										MenuProps={{
											style: { zIndex: 15000 },
										}}
										label="Tipo de pedido"
										labelId="select-tipo-label"
										id="select-tipo"
										value={tipoSelected}
										onChange={(e) => changeSelectTipo(e.target.value)}
									>
										{tipoOptions.map((tipo) => (
											<MenuItem key={tipo.id} value={tipo.id}>
												{tipo.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							) : null}
						</div>
					</div>
				</div>
			</div>
			{pedidos.length === 0 && !state.appReducer.loading && (
				<div className="w3-block bs-bbox">
					<h3 className="w3-text-gray">No hay resultados</h3>
				</div>
			)}
			{pedidos.length > 0 ? (
				<div className="w3-block w3-margin-top">
					<table id="tabla-pedidos" className="w3-table w3-border w3-bordered w3-white">
						<thead className="w3-border">
							<tr>
								<th>Id</th>
								<th>Cliente</th>
								<th>Fecha</th>
								<th>Entrega</th>
								<th>Monto total</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody className="w3-border">
							{pedidos.map((item, index) => (
								<tr
									key={item.id}
									className={item.anchorEl ? 'w3-light-gray' : ''}
								>
									<td>{item.id}</td>
									<td>{getNombreCompleto(item.usuario)}</td>
									<td>{Helper.datetimeToUiFormat(item.recibido)}</td>
									<td>
										<Tags
											tags={[
												getTagProperties(item.tipo)
											]}
										/>
									</td>
									<td>{Helper.numberUi(item.montoTotal)} Gs.</td>
									<td style={{ width: '120px' }}>
										<FontAwesomeIcon
											icon={faCircle}
											color={getEstado(item).color}
										/>
										<b> {getEstado(item).estado}</b>
									</td>
									<td style={{ width: '120px' }}>
										<BotonAccionInfo
											variant="contained"
											color="primary"
											aria-controls={'menu-opciones-' + item.id}
											aria-haspopup="true"
											onClick={(e) => onClickOpciones(e, index)}
										>
											Opciones
										</BotonAccionInfo>
										<Menu
											id={'menu-opciones-' + item.id}
											anchorEl={item.anchorEl}
											keepMounted
											open={Boolean(item.anchorEl)}
											onClose={() => clickOpcion('cerrar', item, index)}
										>
											<MenuItem onClick={() => clickOpcion('ver', item, index)}>
												Ver
											</MenuItem>
											<MenuItem
												onClick={() => clickOpcion('cancelar', item, index)}
											>
												Cancelar
											</MenuItem>

											<hr />
											<MenuItem>
												<Switch
													checked={item.estados.procesando}
													onChange={(e) => onChangeEstado(e, item, index)}
													name={`procesando-${item.id}`}
												/>
												<span>&nbsp;&nbsp;Procesando</span>
											</MenuItem>
											{getEstado(item).estado !== 'Listo' ? (
												<MenuItem>
													<Switch
														checked={item.estados.listo}
														onChange={(e) => marcarListo(e, item, index)}
														name={`listo-${item.id}`}
														disabled={!item.estados.procesando}
													/>
													<span>&nbsp;&nbsp;Listo</span>
												</MenuItem>
											) : null}
											{getEstado(item).estado === 'Listo' ? (
												<MenuItem>
													<Switch
														checked={item.estados.listo}
														onChange={(e) => desmarcarListo(e, item, index)}
														name={`listo-${item.id}`}
													/>
													<span>&nbsp;&nbsp;Listo</span>
												</MenuItem>
											) : null}
											<MenuItem>
												<Switch
													checked={item.estados.entregado}
													onChange={(e) => onChangeEstado(e, item, index)}
													name={`entregado-${item.id}`}
													disabled={!item.estados.listo}
												/>
												<span>&nbsp;&nbsp;Entregado</span>
											</MenuItem>
										</Menu>
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="7" className="w3-small">
									<div
										style={{ width: '100%', maxWidth: '300px', float: 'right' }}
									>
										<div className="w3-cell w3-cell-middle">
											<span>Filas por página </span>
										</div>
										<div className="w3-cell">
											{paginador.selectCuantos.length > 0 ? (
												<FormControl variant="outlined">
													<Select
														size="small"
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={paginador.cuantos}
														onChange={(e) => cambioCuantos(e)}
														input={<BootstrapInput />}
													>
														{paginador.selectCuantos.map((option) => (
															<MenuItem key={option.value} value={option.value}>
																{option.label}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											) : null}
										</div>
										<div className="w3-cell w3-cell-middle">
											<span>
												{filtros.desde + 1} -{' '}
												{filtros.desde + paginador.cuantos >
													paginador.numResultados
													? paginador.numResultados
													: filtros.desde + paginador.cuantos}{' '}
												de {paginador.numResultados}{' '}
											</span>
										</div>
										<div className="w3-cell w3-cell-middle">
											<IconButton
												aria-label="Atrás"
												onClick={() => atras()}
												size="small"
												className={classes.margin}
												disabled={filtros.desde <= 1}
											>
												<FontAwesomeIcon icon={faChevronLeft} size="sm" />
											</IconButton>
											<IconButton
												aria-label="Siguiente"
												onClick={() => siguiente()}
												size="small"
												className={classes.margin}
												disabled={
													filtros.desde + paginador.cuantos >
													paginador.numResultados
												}
											>
												<FontAwesomeIcon icon={faChevronRight} size="sm" />
											</IconButton>
										</div>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			) : null}

			<SlidingPanel type={'right'} isOpen={panelVer} size={100}>
				<PanelContainer style={{ zIndex: '100 !important' }}
					close={cancelar}
					panelLabel="Ver pedido"
					closeLabel="Pedidos"
				>
					<VerPedido
						cancelar={cancelar}
						pedido={pedido}
						configPedidos={configPedidos}
					/>
				</PanelContainer>
			</SlidingPanel>

			<SlidingPanel type={'right'} isOpen={panelCrear} size={100}>
				<PanelContainer
					close={cancelar}
					panelLabel="Nuevo pedido"
					closeLabel="Pedidos"
				>
					<CrearPedido
						cancelar={cancelar}
						configPedidos={configPedidos}
						buscarResultados={buscarResultados}
						filtros={filtros}
					/>
				</PanelContainer>
			</SlidingPanel>

			<SlidingPanel type={'right'} isOpen={panelEditar} size={100}>
				<PanelContainer
					close={cancelar}
					panelLabel="Edición pedido"
					closeLabel="Pedidos"
				>
					<EditarPedido
						cancelar={cancelar}
						pedido={pedido}
						configPedidos={configPedidos}
						buscarResultados={buscarResultados}
						filtros={filtros}
					/>
				</PanelContainer>
			</SlidingPanel>

			{modalEliminar && pedido ? (
				<div className="w3-modal w3-show">
					<div className="w3-modal-content w3-animate-zoom w3-round-large">
						<div className="w3-row">
							<div className="w3-col w3-right" style={{ width: '50px' }}>
								<IconButton aria-label="delete" onClick={() => cancelar()}>
									&nbsp;
									<FontAwesomeIcon icon={faTimes} />
									&nbsp;
								</IconButton>
							</div>
							<div className="w3-rest w3-padding w3-center w3-text-gray">
								<h2 style={{ margin: 0 }}>
									<FontAwesomeIcon icon={faExclamationTriangle} /> Atención
								</h2>
							</div>
						</div>
						<div className="w3-container w3-padding">
							<p className="w3-large w3-margin w3-center">
								Estás a punto de eliminar el pedido con id: <b>{pedido.id}</b>.
								Ésta acción no se puede deshacer.
							</p>
							<p className="w3-large w3-margin w3-center">
								¿Quieres continuar?
							</p>
							<div className="w3-row w3-white">
								<div className="w3-col l6" style={{ padding: '30px 16px' }}>
									<button
										className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
										type="button"
										onClick={() => cancelar()}
									>
										No
									</button>
								</div>
								<div className="w3-col l6" style={{ padding: '30px 16px' }}>
									<button
										className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
										type="button"
										onClick={() => confirmarEliminar()}
									>
										Si
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
			{modalCancelarPedido && pedido ? (
				<div className="w3-modal w3-show">
					<div className="w3-modal-content w3-animate-zoom w3-round-large">
						<div className="w3-row">
							<div className="w3-col w3-right" style={{ width: '50px' }}>
								<IconButton
									aria-label="delete"
									onClick={() => cancelarCancelarPedido()}
								>
									&nbsp;
									<FontAwesomeIcon icon={faTimes} />
									&nbsp;
								</IconButton>
							</div>
							<div className="w3-rest w3-padding w3-center w3-text-gray">
								<h2 style={{ margin: 0 }}>
									<FontAwesomeIcon icon={faExclamationTriangle} /> Motivo de
									cancelación del pedido
								</h2>
							</div>
						</div>
						<div className="w3-container w3-padding">
							<div className="w3-row">
								<div className="w3-col l6 w3-padding-small bs-bbox">
									<FormControl component="fieldset">
										<RadioGroup
											aria-label="gender"
											name="motivoCancelacion"
											value={motivoCancelacion}
											onChange={(e) => onChangeMotivo(e)}
										>
											<FormControlLabel
												value="Stock insuficiente"
												control={<RadioTheme />}
												label="Stock insuficiente"
											/>
											<FormControlLabel
												value="otro"
												control={<RadioTheme />}
												label="Otro"
											/>
										</RadioGroup>
									</FormControl>
								</div>
								{motivoCancelacion === 'otro' ? (
									<div className="w3-col l12 w3-padding-small bs-bbox">
										<TextField
											fullWidth
											margin="normal"
											size="small"
											variant="outlined"
											id="input-motivo"
											label="Escribir motivo cancelación"
											type="text"
											name="motivo"
											value={otroMotivoCancelacion}
											onChange={onChangeOtroMotivo}
											disabled={state.appReducer.actionsDisabled}
										/>
									</div>
								) : null}
							</div>
							<div className="w3-row w3-white">
								<div
									className="w3-col l6 bs-bbox"
									style={{ padding: '30px 16px' }}
								>
									<button
										className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
										type="button"
										onClick={() => cancelarCancelarPedido()}
									>
										No cancelar
									</button>
								</div>
								<div
									className="w3-col l6 bs-bbox"
									style={{ padding: '30px 16px' }}
								>
									<button
										className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
										type="button"
										onClick={() => confirmarCancelarPedido()}
										disabled={!motivoCancelacion}
									>
										Confirmar cancelación
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
export default withRouter(PedidosHistorial);
