import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { DefinedRange } from 'react-date-range';

import { DispatchContext } from 'context';
import { Api } from 'services';
import { AppActions } from 'store';
import MasVendidosChart from './MasVendidosChart';
import { defaultInputRanges, defaultStaticRanges } from './defaultRanges';

import CONFIG from "config";
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Helper } from 'utils';

export default function MasVendidosRango() {
	const getComienzoMes = () => {
		let month = new Date().getMonth() + 1;
		const year = new Date().getFullYear();

		if (month.toString().length < 2) {
			month = '0' + month;
		}
		return new Date(year + '/' + month.toString() + '/01');
	};

	const getComienzoMesPasado = () => {
		let month = new Date().getMonth(); // +1 sería el mes actual
		const year = new Date().getFullYear();

		if (month.toString().length < 2) {
			month = '0' + month;
		}
		return new Date(year + '/' + month.toString() + '/01');
	};

	const getFinMesPasado = () => {
		let month = new Date().getMonth(); // +1 sería el mes actual
		const year = new Date().getFullYear();

		return new Date(year, month, 0)
	}

	const [rangoSeleccion, setRangoSeleccion] = useState({
		startDate: getComienzoMesPasado(),
		endDate: getFinMesPasado(),
		key: 'selection',
	})

	const [ventasData, setVentasData] = useState([]);
	const dispatch = useContext(DispatchContext);


	const handleSelect = (ranges) => {
		setRangoSeleccion(ranges.selection);
	}

	const onClickClear = () => {
		setRangoSeleccion({
			startDate: getComienzoMesPasado(),
			endDate: getFinMesPasado(),
			key: 'selection',
		})
	}

	

	const getVentaData = () => {
		dispatch(AppActions.setLoading(true));
		const options = {
			url: Api.route(
				`t/reportes/productos-mas-vendidos-rango/${Helper.dateToServerFormat(rangoSeleccion.startDate)}/${Helper.dateToServerFormat(rangoSeleccion.endDate)}`
			),
			method: 'GET',
		};
		Api.requestAxios(options, (response) => {
			if (response.error) {
				console.error('There was an error');
				dispatch(AppActions.setLoading(false));
			} else {
				setVentasData(response.data.datos);
				dispatch(AppActions.setLoading(false));
			}
		});
	}

	return (
		<>
			<h1>Productos más vendidos por rango</h1>
			<div className="w3-row w3-center">
				<div className="w3-col w3-padding-small bs-bbox">
					<DateRangePicker
						staticRanges={defaultStaticRanges}
						inputRanges={defaultInputRanges}
						maxDate={new Date()}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						months={2}
						direction='horizontal'
						ranges={[rangoSeleccion]}
						onChange={(ranges) => handleSelect(ranges)}
					/>
				</div>
			</div>
			<div className="w3-row w3-center">
				<div className="w3-col">
					<button
						className="w3-btn w3-ripple w3-blue w3-margin-right"
						type="button"
						onClick={() => getVentaData()}
					>
						Mostrar
					</button>
					<button
						className="w3-btn w3-white w3-border"
						onClick={onClickClear}
					>
						Limpiar
					</button>
				</div>
			</div>
			{ventasData.length ?
				<>
					<MasVendidosChart products={ventasData} />
					<div className="w3-row w3-center">
						<div className="w3-col w3-padding-small bs-bbox">
							<a
								className="w3-btn w3-ripple w3-green w3-margin-right"
								type="button"
								href={`${CONFIG.URL_BASE}a/reportes/descargar-productos-vendidos/${Helper.dateToServerFormat(rangoSeleccion.startDate)}/${Helper.dateToServerFormat(rangoSeleccion.endDate)}`}
							>
								<FontAwesomeIcon icon={faFileExcel} size="sm" /> Descargar listado
							</a>
						</div>
					</div>
				</>
				: null
			}
		</>
	);
}
