import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './sidenav.css';
const PanelContainer = (props) => {
    const { children, closeLabel, panelLabel } = props;
    return (
      <div className="w3-white">
        <div className="w3-container w3-theme">
          <div className="w3-row">
            <div className="w3-col l12 w3-padding bs-bbox">
              <button className="w3-btn w3-white w3-round-xlarge w3-text-theme w3-margin-right"
                onClick={() => props.close()}><FontAwesomeIcon icon={faChevronLeft} /> {closeLabel}</button>
              <span className="w3-large">&nbsp;{panelLabel}</span>
            </div>
          </div>
        </div>
        {children}
      </div>
    );
};
export default PanelContainer;