// Initial State
const appInitialState = {
    actionsDisabled: false,
    loading: false,
    lastVisited: 'login',
    sideMenuClass: {
        background: 'w3-theme-d4',
        active: 'w3-theme',
        hover: 'w3-hover-theme-d1',
        toggleActive: 'w3-theme',
        toggleHover: 'w3-hover-theme-d1',
    },
    menuAbierto: false,
    itemsCarrito: {},
    paginaActual: null,
    precioDelivery: null,
    pedido: null
};
// Reducers (Modifies The State And Returns A New State)
const appReducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            return {
                ...appInitialState
            };
        }
        case 'ACTIONS_DISABLED': {
            return {
                ...state,
                actionsDisabled: action.actionsDisabled,
            }
        }
        case 'LOADING': {
            return {
                ...state,
                loading: action.loading,
            }
        }
        case 'LAST_VISITED': {
            return {
                ...state,
                lastVisited: action.lastVisited,
            }
        }
        case 'SIDE_MENU_CLASS': {
            return {
                ...state,
                sideMenuClass: action.sideMenuClass,
            }
        }
        case 'PEDIDO': {
            return {
                ...state,
                pedido: action.pedido
            }
        }
        case 'PAGINA_ACTUAL': {
            return {
                ...state,
                paginaActual: action.paginaActual
            }
        }
        case 'PRECIO_DELIVERY': {
            return {
                ...state,
                precioDelivery: action.precioDelivery
            }
        }
        default: {
            return state;
        }
    }
};
// Exports
export { appReducer, appInitialState };