import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from "react-router-dom";
import { StateContext } from 'context';
import './sidenav.css';
const Navlink = (props) => {
    const { label, location, to, icon } = props;
    const { pathname } = location;
    const state = useContext(StateContext);
    return (
      <Link to={to} className={"w3-bar-item w3-button " + state.appReducer.sideMenuClass.hover + ((pathname === to) ? ' ' + state.appReducer.sideMenuClass.active : '')} style={{ boxSizing: 'border-box' }}>
        <div className="w3-row" style={{ boxSizing: 'border-box' }}>
          <div className="w3-col" style={{ width: '30px' }}>
            <FontAwesomeIcon icon={ icon } className="w3-margin-right w3-color-theme-l3"/>
          </div>
          <div className="w3-col w3-right" style={{ width: '30px' }}>
            {pathname === to && <FontAwesomeIcon icon={ faChevronRight } pull="right" />}
          </div>
          <div className="w3-rest">{ label }</div>
        </div>
      </Link>
    );
};
export default withRouter(Navlink);