import React, { useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { Alert } from 'components/w3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faPencilAlt, faTrash, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import SlidingPanel from 'react-sliding-side-panel';
import { withRouter } from "react-router-dom";
import { DispatchContext, StateContext } from 'context';
import { AppActions, AuthActions, PerfilActions } from 'store';
import { useSnackbar } from 'notistack';

import './listas.css';
const Listas = props => {
    const { enqueueSnackbar } = useSnackbar();
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const { listas, setListas } = useState([]);
    const { lista, setLista } = useState(null);
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Listas';
        getListas();
    }, []);
    const getListas = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/listas'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getListas response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setListas(response.data.datos);
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, error => {
            Helper.log('error getListas', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos', { variant: 'error' });
        });
    }
    return (<div className="w3-container content bs-bbox" style={{ paddingTop: '40px' }}>
            {!state.appReducer.loading && <div className="w3-row">
                <div className="w3-col l6">
                    <button className="w3-btn w3-ripple w3-green"
                        title="crear nuevo usuario"
                        onClick={() => this.crear()}
                        disabled={state.appReducer.actionsDisabled ? 'disabled' : ''}><FontAwesomeIcon icon={faPlus} /> Nuevo usuario</button>
                </div>
                <div className="w3-col l6">
                    <div className="w3-cell w3-right">
                        <input className="w3-input w3-border"
                            style={{ width: '260px' }}
                            placeholder="Buscar"
                            type="text"
                            ng-model="usuariosSearchText"
                            disabled={state.appReducer.actionsDisabled ? 'disabled' : ''} />
                    </div>
                </div>
            </div>}
            {listas.length === 0 && !state.appReducer.loading && <div className="w3-block bs-bbox">
                <Alert type="info" content={'No hay resultados'} clickabled={false}></Alert>
            </div>}
            {listas.length > 0 && <table className="w3-table w3-bordered w3-border w3-margin-top table-td-middle">
                <thead>
                    <tr className="w3-theme-l5">
                        <th>Id</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {listas.map((usr) =>
                        <tr key={usr.id}>
                            <td>{usr.nombre}</td>
                            <td className="buttons-col">
                                {/* <button className="w3-btn w3-purple"
                                    title="Ver"
                                    onClick={() => this.ver(usr)}><FontAwesomeIcon icon={faEye} /></button>
                                <button className="w3-btn w3-blue"
                                    title="Editar"
                                    onClick={() => this.editar(usr)}><FontAwesomeIcon icon={faPencilAlt} /></button> */}
                            </td>                            
                        </tr>)}
                </tbody>
            </table>}

            {!state.appReducer.loading && <ReactPaginate pageCount={2}
                pageRangeDisplayed={5}
                marginPagesDisplayed={5}
                pageClassName="w3-btn w3-border paginator-li"
                previousLabel="Anterior"
                nextLabel="Siguiente"
                activeClassName="w3-blue"
                previousClassName="w3-btn w3-border paginator-li"
                nextClassName="w3-btn w3-border paginator-li" />}
           
        </div>);
};
export default withRouter(Listas);