import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import CONFIG from 'config';
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { DispatchContext } from 'context';
import './dashboard-pedidos.css';
import DiaCard from '../../components/card/DiaCard';
import { Tags } from 'components/w3';

const DashboardPedidos = () => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const [data, setData] = useState(null);
	const [ultimosPedidos, setUltimosPedidos] = useState(null);
	const [enCurso, setEnCurso] = useState(0);
	const [entregados, setEntregados] = useState(0);

	useEffect(() => {
		dispatch(AppActions.setLoading(false));
		dispatch(AppActions.setActionsDisabled(false));
		document.title = CONFIG.APP_NAME + ' - Dashboard admin';
		getDashboard();
	}, []);

	const inicializarDatos = (datos) => {
		if (datos) {
			setEnCurso(datos.numPedidosEnCurso);
			setEntregados(datos.numPedidosEntregados);
			setUltimosPedidos(datos.ultimosPedidos);
		}
	};
	const getDashboard = () => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('t/dashboard'),
			method: 'GET',
		};
		Api.requestAxios(
			options,
			(response) => {
				Helper.log('getDashboard response', response);
				if (response.data.error) {
					enqueueSnackbar(response.data.msg, { variant: 'error' });
				} else {
					setData(response.data.datos);
					inicializarDatos(response.data.datos);
				}
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
			},
			(error) => {
				Helper.log('error getDashboard', error);
				dispatch(AppActions.setLoading(false));
				dispatch(AppActions.setActionsDisabled(false));
				enqueueSnackbar('Error al traer datos', { variant: 'error' });
			}
		);
	};
	const getEstado = (ped) => {
		let status = {
			estado: 'Pendiente',
			color: '#2067FA',
		};
		if (ped.recibido) {
			status = {
				estado: 'Pendiente',
				color: '#2067FA',
			};
		}
		if (ped.procesando) {
			status = {
				estado: 'Procesando',
				color: '#666D92',
			};
		}
		if (ped.listo) {
			status = {
				estado: 'Listo',
				color: '#00C58D',
			};
		}
		if (ped.cancelado) {
			status = {
				estado: 'Cancelado',
				color: '#FC5C63',
			};
		}
		if (ped.entregado) {
			status = {
				estado: 'Entregado',
				color: '#00C58D'
			};
		}
		return status;
	};
	const getNombreCompleto = (user) => {
		let nombre = '--';
		if (user) {
			if (user.nombre) {
				nombre = user.nombre;
			}
			if (user.nombre && user.apellido) {
				nombre = user.nombre + ' ' + user.apellido;
			}
		}
		return nombre;
	};
	const getTagProperties = (tipo) => {
		let properties = {
			texto: '...',
			backgroundColor: '#fff',
			color: '#000'
		}

		switch (tipo) {
			case 'online-retiro':
				properties = {
					texto: 'Retiro del local',
					backgroundColor: '#438BCA',
					color: '#fff',
				}
				break;
			case 'envio-interior':
				properties = {
					texto: 'Envío al interior',
					backgroundColor: '#F56D06',
					color: '#fff',
				}
				break;
			case 'online-envio':
				properties = {
					texto: 'Pagado con envío',
					backgroundColor: '#B24598',
					color: '#fff',
				}
				break;
			case 'envio':
				properties = {
					texto: 'Pago contra entrega',
					backgroundColor: '#339933',
					color: '#fff',
				}
				break;
		}

		return properties
	}
	return (
		<div>
			{data ? (
				<div>
					{data ? (
						<div className="w3-row">
							<h1>Pedidos</h1>
							<DiaCard title="En curso" value={enCurso} />
							<DiaCard title="Entregados del día" value={entregados} />
						</div>
					) : null}
					{ultimosPedidos ? (
						<div className="w3-row">
							<div className="w3-col l12 w3-padding bs-bbox">
								<h3>Últimos pedidos</h3>
								<div
									className="w3-card w3-white w3-round-large"
									style={{ padding: '16px 16px 60px 16px' }}
								>
									{ultimosPedidos.length > 0 ? (
										<div className="w3-block w3-margin-top">
											<table className="w3-table w3-border w3-bordered tabla w3-white">
												<thead className="w3-border">
													<tr>
														<th>Id</th>
														<th>Cliente</th>
														<th>Fecha</th>
														<th>Tipo</th>
														<th>Monto total</th>
														<th>Estado</th>
														<th></th>
													</tr>
												</thead>
												<tbody className="w3-border">
													{ultimosPedidos.map((item, index) => {
														return (
															<tr
																key={item.id}
																className={item.finalizado === 1 ? 'finalizado' : ''}
															>
																<td>{item.id}</td>
																<td>{getNombreCompleto(item.usuario)}</td>
																<td>
																	{Helper.datetimeToUiFormat(item.recibido)}
																</td>
																<td>
																	<Tags
																		tags={[
																			getTagProperties(item.tipo)
																		]}
																	/>
																</td>
																<td>{Helper.numberUi(item.montoTotal)} Gs.</td>
																<td style={{ width: '120px' }}>
																	<FontAwesomeIcon
																		icon={faCircle}
																		color={getEstado(item).color}
																	/>
																	{` ${getEstado(item).estado}`}
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									) : null}
								</div>
							</div>
						</div>
					) : null}
				</div>
			) : null}
		</div>
	);
};
export default withRouter(DashboardPedidos);
