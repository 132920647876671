import React, { useContext } from 'react';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppStorage } from 'utils';
import { StateContext } from 'context';
import './sidenav.css';
const Navtoggle = (props) => {
    const { label, icon, toggle } = props;
    const state = useContext(StateContext);
    return (
        <button onClick={props.click} className={"w3-bar-item w3-medium w3-button toggle-button " + state.appReducer.sideMenuClass.toggleHover + ((toggle) ? ' ' + state.appReducer.sideMenuClass.toggleActive : '')} style={{ boxSizing: 'border-box' }}>
            <div className="w3-row" style={{ boxSizing: 'border-box' }}>
                <div className="w3-col" style={{ width: '30px' }}>
                    <FontAwesomeIcon icon={icon} className="w3-margin-right w3-color-theme-l3" />
                </div>
                <div className="w3-rest">
                    <span>{label}</span>
                    <FontAwesomeIcon icon={(toggle) ? faMinusCircle : faPlusCircle} className="w3-right w3-color-theme-l3 w3-small toggle-button-right-icon" />
                </div>
            </div>
        </button>
    );
};
export default Navtoggle;