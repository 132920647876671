const production = true;
const ID = 'ferrex-admin';
const APP_NAME = 'FIX';
const GOOGLE_MAP_KEY = 'AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s';
const GOOGLE_RECAPTCHA_SITEKEY = '6LdGty4UAAAAACrpeTUV7sQGFl5KbGMjJHpLuOrE';
const DEVELOPMENT = {
    ID: ID,
    APP_NAME: APP_NAME,
    //HOST: 'http://192.168.100.8/fix-admin',//localhost
    //BASENAME: '/fix-admin', //subdirectorio del host
	HOST: 'http://192.168.100.39/fix/admin',//localhost
    BASENAME: '/', //subdirectorio del host
    URL_BASE: 'http://192.168.100.39/fix/server/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: true
}
const PRODUCTION = {
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'https://admin.ferrex.com.py',//production (copiar y pegar en package.json --> homepage)
    BASENAME: '/',//subdirectorio
    URL_BASE: 'https://api3.fix.com.py/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: false
}
export default (production) ? PRODUCTION : DEVELOPMENT;